<template>
	<div class="consultation">
		<el-breadcrumb separator=">" class="path">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				首页
			</el-breadcrumb-item>
			<!-- :to="{ path: '/cms/help' }" -->
			<el-breadcrumb-item >数据资讯</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">资讯详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="consulation_content">
			<div class="detail">
				<div class="title">{{detail.title}}</div>
				<div class="watch_data">
					<div class="time"><i class="el-icon-time"></i>{{$util.timeStampTurnTime(detail.create_time)}}</div>
					<div class="browse"><i class="el-icon-view"></i>{{detail.read}}</div>
				</div>
				<div class="content" v-html="detail.content"></div>
			</div>
			<div class="right_info">
				<div class="hot_point">
					<div class="tit">
						<img :src="$img('/upload/web/left_tiao.png')" alt="">
						<div class="name">热点资讯</div>
						<img :src="$img('/upload/web/right_tiao.png')" alt="">
					</div>
					<el-carousel :loop="false" indicator-position="outside" class="swiper_news">
						<el-carousel-item v-for="(item,index) in recentConsultationList" :key="index" @click="toConsultationDetail(item.id)">
							<div class="news_img">
								<img :src="$img(item.image_url)" alt="" >
							</div>
							<div class="news_name">{{item.title}}</div>
							<div class="desc">{{item.introduction || '暂无介绍'}}</div>
						</el-carousel-item>
					</el-carousel>
					<div class="news_list">
						<div class="item" v-for="(item,index) in recentConsultationList" :key="index">
							<div class="number">{{(index+1)<10 ? '0'+(index+1) : index}}</div>
							<div class="desc">{{item.title}}</div>
						</div>
					</div>
				</div>
				<div class="recent">
					<div class="tit">
						<img :src="$img('/upload/web/left_tiao.png')" alt="">
						<div class="name">最新资讯</div>
						<img :src="$img('/upload/web/right_tiao.png')" alt="">
					</div>
					<div class="new_list">
						<div class="item" v-for="(item,index) in recentConsultationList" :key="index" @click="toConsultationDetail(item.id)">
							<div class="juxing"></div>
							<div class="desc">
								{{item.title}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ReadConsultationInfo,
		ConsultationPageList
	} from "@/api/index.js"
	export default {
		data() {
			return {
				id:'',
				detail:'',
				hotConsultationList:[],
				recentConsultationList:[],
			}
		},
		created() {
			this.id = this.$route.params.pathMatch;
			this.getDetail();
			this.getHotConsultationList();
			this.getRecentConsultationList();
		},
		methods: {
			toConsultationDetail(id){
				this.$router.pushToTab('/consultation/detail-'+id)
			},
			getRecentConsultationList(){
				ConsultationPageList({
					page:1,
					page_size:5,
					type:1
				})
				.then(res=>{
					if(res.code>=0){
						this.recentConsultationList = res.data.list;
					}
				})
			},
			//热点
			getHotConsultationList(){
				ConsultationPageList({
					page:1,
					page_size:5,
					type:2
				})
				.then(res=>{
					if(res.code>=0){
						this.hotConsultationList = res.data.list;
					}
				})
			},
			getDetail() {
				ReadConsultationInfo({
					id:this.id
				})
				.then(res=>{
					if(res.code>=0){
						this.detail = res.data;
					}
				})
				.catch(err=>{
					
				})
			}
		},
	}
</script>

<style lang="scss">
	.el-main{
		// width: 100% !important;
		.consultation{
			width: 1200px;
			margin: 0 auto;
		}
	}
	body{
		// background-color: #f8f8f8 !important;
	}
	.path{
		padding: 17px 0;
		color: #666;
		font-size: 14px;
		line-height: 20px;
		.el-breadcrumb__item{
			display: flex;
			align-items: center;
			.el-breadcrumb__separator{
				color: #666;
				font-weight: normal;
				font-size: 14px;
			}
			.el-breadcrumb__inner{
				color: #666 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				&.is-link{
					font-weight: normal !important;
					color: #666 !important;
					font-size: 14px !important;
					line-height: 20px !important;
				}
			}
		}
	}
	.swiper_news{
		.el-carousel__container{
			height: 241px;
		}
		.el-carousel__indicator{
			padding:20px 6px 18px;
			button{
				width: 18px;
				height: 4px;
				background-color: #ECECEC;
				opacity: 1 !important;
			}
			&.is-active{
				button{
					background-color: #0091FF;
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	.consulation_content{
		padding-bottom: 30px;
		display: flex;
		align-items: flex-start;
		.detail{
			flex:1;
			background-color: #fff;
			padding: 40px 0 74px;
			.title{
				color: #000;
				text-align: center;
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 18px;
			}
			.watch_data{
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 15px;
				border-bottom: 1px solid #F4F5F7;
				.time,.browse{
					display: flex;
					align-items: center;
					color: #A2A2A2;
					font-size: 12px;
					line-height: 17px;
					font-family: auto;
					i{
						margin-right: 7px;
					}
				}
				.time{
					margin-right: 45px;
				}
			}
			.content{
				padding: 20px 28px 0;
			}
		}
		.right_info{
			min-width: 314px;
			width: 314px;
			margin-left: 20px;
			.hot_point{
				background-color: #fff;
				padding: 19px 20px 30px;
				margin-bottom: 20px;
				.news_list{
					.item{
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						cursor: pointer;
						&:last-child{
							margin-bottom: 0;
						}
						.number{
							color: #FA6400;
							font-size: 16px;
							line-height: 22px;
							margin-right: 8px;
						}
						.desc{
							flex: 1;
							color: #444;
							font-size: 16px;
							line-height: 22px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
					}
				}
				.tit{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 20px;
					.name{
						color: #333;
						font-size: 18px;
						line-height: 24px;
						margin: 0 16px;
					}
					img{
						width: 36px;
						height: 2px;
						display: block;
					}
				}
				.swiper_news{
					.news_img{
						width: 100%;
						height: 154px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 14px;
						cursor: pointer;
					}
					.news_name{
						color: #333;
						font-size: 18px;
						line-height: 25px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						margin-bottom: 8px;
						cursor: pointer;
					}
					.desc{
						cursor: pointer;
						color: #888;
						font-size: 14px;
						line-height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
			.recent{
				padding: 19px 20px 49px 26px;
				background-color: #fff;
				.tit{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 18px;
					.name{
						color: #333;
						font-size: 18px;
						line-height: 24px;
						margin: 0 16px;
					}
					img{
						width: 36px;
						height: 2px;
						display: block;
					}
				}
				.new_list{
					.item{
						display: flex;
						align-items: baseline;
						margin-bottom: 20px;
						cursor: pointer;
						&:last-child{
							margin-bottom: 0;
						}
						.desc{
							color: #444;
							font-size: 16px;
							line-height: 24px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						.juxing{
							min-width: 8px;
							width: 8px;
							height: 8px;
							background-color: #9DA5AE;
							margin-right: 14px;
						}
					}
				}
			}
		}
	}
</style>